import { useEffect, useState } from 'react'
import axios from "axios";

export const Middleware = (props) => {

  const [isLoading, setLoading] = useState(true);
  const [isValidated, setValidation] = useState(false);
  
  function setInvalid(){
    setLoading(false);
    setValidation(false);
  }

  function setValid(){
    setLoading(false);
    setValidation(true);
  } 

  useEffect(() => {
    
    const baseURL = `/user/`;

  axios.get(baseURL, {withCredentials:true}).then((res) => {
    if (res.status !== 200)setInvalid();
    else {
      console.log(res.data)
      props.setuserDetails(res.data);
      setValid();}
  })
    .catch((err) => {
      //Navigate
      setInvalid();
    });
  }, []);

  if (isLoading) {
    return (<div></div>);
  }

 if(!isLoading && isValidated) {
  return props.children;
  }

}