import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from "axios";
import { useSignIn } from 'react-auth-kit';

export const Login = (props) => {

  const navigate = useNavigate();
  var { state } = useLocation();

  const [invalidAlert, setinvalidAlert] = useState(false);


  const signIn = useSignIn();

  //Listeners
  const BtnClickLogin = async () => {

    var username = document.getElementById('email').value;
    const baseURL = `/user/login`;
    try {
      const response = await axios.post(
        baseURL,
        {
          username: document.getElementById('email').value,
          password: document.getElementById('password').value
        }
      );

      signIn({
        token: response.data.token,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: { username },
      });
      navigate("/");

    } catch (err) {
      setinvalidAlert(true)
      if (err && err instanceof AxiosError) { }
      else if (err && err instanceof Error) { }

      console.log("Error: ", err);
    }

  }


  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        BtnClickLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return (<>
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-10 w-auto" src="http://modal.systems/wp-content/uploads/2021/04/logo2.png" />
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            <label for="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
            <div className="mt-2">
              <input id="email" name="email" type="email" autocomplete="email" required className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label for="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
            </div>
            <div className="mt-2">
              <input id="password" name="password" type="password" autocomplete="current-password" required className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>


        </form>
        <div>
          <button onClick={BtnClickLogin} type="submit" className="flex w-full justify-center rounded-md bg-[#bb4d2a] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-5 mb-6">Sign in</button>
        </div>
        <div className={`mb-6 ${invalidAlert?'':'hidden'}`} role="alert">
          <div className="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
            <div className="flex">
              <div><svg className="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
              <div>
                <p className="text-sm">Invalid credentials</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </>)
}