import { useState, useEffect } from "react";
const Event = ({ transcripts, settranscript, handleExpand }) => {

    const [show, setshow] = useState(false);

    return (<>

        {transcripts.map((user) => {
            return (
                <>

                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th colspan="100%" scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                            <button onClick={() => handleExpand(user.userId)}>{user.userId.slice(0, 60)}</button>
                        </th>
                        <td className="px-6 py-4">
                                {user.eventType}
                                </td>
                                <td className="px-6 py-4">
                                {user.time}
                                </td>
                                <td className="px-6 py-4">
                                {JSON.stringify(user.event)}
                                </td>
                    </tr>
                </>
            )
        })}
    </>)
}

export default Event;