import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios, { AxiosError } from "axios";
import { useSignOut } from 'react-auth-kit';

export const Channels = (props) => {

    const navigate = useNavigate();
    var { state } = useLocation();
    const signOut = useSignOut();

    const [channels, setchannels] = useState([]);

    console.log('Channels props', props);



    useEffect(() => {
        const baseURL = `/user/channels`;

        axios.get(baseURL, { withCredentials: true }).then((res) => {
            if (res.status !== 200) { }
            else {
                setchannels(res.data)
            }

        })
            .catch((err) => {

            });


    }, []);
    return (<>

        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">

            <div className="relative mt-10">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Display Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Locale
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Brand
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Transcripts
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Events
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Lexicon
                            </th>
                            <th scope="col" className="px-2 py-3">
                                Create QR
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Type
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Platform
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Unique ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Website
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      {channels.map((channel) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {channel.displayName}
                                </th>
                                <td className="px-6 py-4">
                                {channel.locale}
                                </td>
                                <td className="px-6 py-4">
                                {channel.brand}
                                </td>
                                <td className="px-6 py-4">
                                <Link to="/transcripts" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2 dark:bg-blue-400 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800" state={{channel}}>Transcript</Link>
                                </td>
                                <td className="px-6 py-4">
                                <Link to="/events" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2 dark:bg-blue-400 dark:hover:bg-blue-500 focus:outline-none dark:focus:ring-blue-800" state={{channel}}>Events</Link>
                                </td>
                                <td className="px-6 py-4">
                                <Link to="/lexicon" className="text-white bg-[#bb4d2a] focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800" state={{channel}}>Lexicon</Link>
                                </td>
                                <td className="px-6 py-4">
                                <Link to="/createqr" className="text-white hover:text-black bg-black hover:bg-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2 focus:outline-none dark:focus:ring-blue-800" state={{channel}}>QR</Link>
                                </td>
                                <td className="px-6 py-4">
                                {channel.type}
                                </td>
                                <td className="px-6 py-4">
                                {channel.platform}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                {channel.channel_id}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                {channel.website}
                                </td>
                            </tr>
                      ))}
                    </tbody>
                </table>
            </div>
        </div>

    </>)
}