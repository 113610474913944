import logo from './logo.svg';
import './App.css';
import { React, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { RequireAuth } from "react-auth-kit";

import {Login} from './components/login';
import {Dashboard} from './components/pages/dashboard';
import {Channels} from './components/pages/channels';
import {Transcripts} from './components/pages/transcripts';
import {Events} from './components/pages/events';
import {Lexicon} from './components/pages/lexicon';
import {CreateQR} from './components/pages/createQR';
import {Middleware} from './components/middleware';
import {Navbar} from './components/navbar'
import { Admin } from './components/pages/admin';
import { Allusers } from './components/pages/allUsers';
import { CreateUser } from './components/pages/createUser';
import { Settings } from './components/pages/settings';


function App() {
  const [userDetails, setuserDetails] = useState({});
  return (
    <Routes>
      <Route
          path="/"
          element={
            <RequireAuth loginPath="/login">
             <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Dashboard userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/channels"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Channels userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/transcripts"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Transcripts userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        {/*<Route
          path="/admin"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Admin userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>*/}
        <Route
          path="/allusers"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Allusers userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/createuser"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <CreateUser userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/settings"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Settings userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/transcripts"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Transcripts userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/events"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Events userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/lexicon"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <Lexicon userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/createqr"
          element={
            <RequireAuth loginPath="/login">
              <Navbar userDetails={userDetails}></Navbar>
             <Middleware setuserDetails={setuserDetails}> <CreateQR userDetails={userDetails}/></Middleware>
            </RequireAuth>
          }
        ></Route>
        <Route path="/login" element={<Login />}></Route>
   </Routes>
  );
}

export default App;
