import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from "axios";
import { useSignOut } from 'react-auth-kit';
import moment from 'moment';
import Pagination from '../pagination';
import Event from '../event';
import Timeselect from '../timeSelect';

export const Events = (props) => {

    const navigate = useNavigate();
    var { state } = useLocation();
    const signOut = useSignOut();

    //console.log('Transcripts props', props);

    const { channel } = state;

    const [transcripts, settranscript] = useState([]);
    const [btnSubmitLoader, setbtnSubmitLoader] = useState(false);
    const [invalidAlert, setinvalidAlert] = useState(false);
    const [invalidAlertText, setinvalidAlertText] = useState('');

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(20);

    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentPosts = transcripts.slice(indexOfFirstPost, indexOfLastPost);
    const paginate = pageNumber => setCurrentPage(pageNumber);



    //Listeners
    const query = async () => {

        setbtnSubmitLoader(true);
        setinvalidAlert(false)
        setinvalidAlertText('')

        const baseURL = `/analytics/channel/eventTable/${channel.channel_id}`;

        axios.post(baseURL, { startTime:document.getElementById('startTime').value, endTime:document.getElementById('endTime').value }, { withCredentials: true }).then((res) => {
            setbtnSubmitLoader(false)
            if (res.status !== 200) { }
            else {
                var fetchedTranscripts = res.data.data;
                fetchedTranscripts = fetchedTranscripts.map(obj => ({ ...obj, show: false }));
                settranscript(fetchedTranscripts);
            }

        })
            .catch((err) => {
                setbtnSubmitLoader(false)
                setinvalidAlert(true)
                setinvalidAlertText(err.response.data.status)
            });

    }

    const today = async () => {

        setbtnSubmitLoader(true);
        setinvalidAlert(false)
        setinvalidAlertText('')

        const baseURL = `/analytics/channel/eventTable/${channel.channel_id}`;
        document.getElementById('startTime').value = moment().format('YYYY-MM-DD');
        document.getElementById('endTime').value = moment().format('YYYY-MM-DD');

        axios.post(baseURL, { startTime:document.getElementById('startTime').value, endTime:document.getElementById('endTime').value }, { withCredentials: true }).then((res) => {
            setbtnSubmitLoader(false)
            if (res.status !== 200) { }
            else {
                var fetchedTranscripts = res.data.data;
                fetchedTranscripts = fetchedTranscripts.map(obj => ({ ...obj, show: false }));
                settranscript(fetchedTranscripts);
            }

        })
            .catch((err) => {
                setbtnSubmitLoader(false)
                setinvalidAlert(true)
                setinvalidAlertText(err.response.data.status)
            });

    }

    const handleExpand = (userId) => {
        var transcriptsBuffer = Array.from(transcripts);
        var foundIndex = transcriptsBuffer.findIndex(x => x.userId == userId);
        transcriptsBuffer[foundIndex].show = !transcriptsBuffer[foundIndex].show;
        settranscript(transcriptsBuffer);
    }

    useEffect(() => {

        if (!channel.channel_id) {
            navigate('/channels');
        }

        document.getElementById('startTime').value= (moment.utc().subtract(7, 'd').startOf('day').format('YYYY-MM-DD'));
        document.getElementById('endTime').value=(moment.utc().subtract(1, 'd').endOf('day').format('YYYY-MM-DD'));




    }, []);
    return (<>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <h1 className="mb-4 text-4xl font-extrabold leading-none text-center tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-gray mt-10">{channel.displayName}</h1>
            <div className="flex space-x-4 mt-10">
            <Timeselect query={query} btnSubmitLoader={btnSubmitLoader} invalidAlert={invalidAlert} invalidAlertText={invalidAlertText} today={today}/>
            </div>
            <div className="relative overflow-x-auto mt-10">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 table-auto">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                User ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Event Type
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Time
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Event
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <Event transcripts={currentPosts} settranscript={settranscript} handleExpand={handleExpand}></Event>
                    </tbody>
                </table>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={transcripts.length}
                    paginate={paginate}
                />
            </div>

        </div>

    </>)
}