import React from 'react';

const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="flex items-center justify-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Page navigation example">
            <ul className="flex items-center -space-x-px h-10 text-base">
                {pageNumbers.map(number => (
                    <li key={number}>
                        <a onClick={() => paginate(number)} className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{number}</a>
                    </li>
                ))}
            </ul>
        </nav>
        </div>


    );
};

export default Pagination;