import { useState, useEffect } from "react";
const Transcript = ({ transcripts, settranscript, handleExpand }) => {

    const [show, setshow] = useState(false);

    return (<>

        {transcripts.map((user) => {
            return (
                <>

                    <tr className="border-b bg-gray-800 border-gray-700">
                        <td colspan="100%" scope="row" className="px-6 py-4 font-medium text-white ">
                            <button onClick={() => handleExpand(user.userId)}>{user.userId.slice(0, 60)}</button>
                        </td>
                    </tr>
                    {
                        user.show ? (
                            Object.keys(user.sessions).map((session) => {
                                var inputCount = 0
                                return (
                                    <>
                                        <tr className="border-b border-gray-700 bg-[#313B72]">
                                            <td scope="row" className="px-6 py-4 font-medium text-white-900 text-black">
                                                {session.slice(0, 40)} {user.sessions[session].timestamp}
                                            </td>
                                            <td scope="row" className="px-6 py-4 font-medium text-white-900 text-black text-nowrap">
                                              Session Duration : {user.sessions[session].sessionDuration} ms
                                            </td>
                                        </tr>
                                        {
                                            user.sessions[session].interactions.map((currentInput) => {
                                                return (
                                                    <>
                                                        <tr className="border-b border-gray-700 bg-[#BB4D2A]">
                                                            <td scope="row" className="px-6 py-4 font-medium text-black">
                                                                <div className="flex">
                                                                    <div className="my-1 w-1/8">
                                                                    <svg className="w-6 h-6 text-white-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 18">
                                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z" />
                                                                    </svg>
                                                                    </div>
                                                                    <p className="my-2 mx-4 w-3/5">{++inputCount}. {currentInput.request.utterance || currentInput.request.source} ({currentInput.request.intent})</p>
                                                                </div>
                                                            </td>
                                                            <td scope="row" className="px-6 py-4 font-medium text-white-900 ">
                                                                {currentInput.request.description}
                                                            </td>
                                                            <td scope="row" className="px-6 py-4 font-medium text-white-900 ">
                                                                {currentInput.request.slots}
                                                            </td>
                                                        </tr>

                                                        {currentInput.response.outputSpeech ?
                                                            (
                                                                <>
                                                                    <tr className="border-b border-gray-700 bg-[#28666E]">
                                                                        <td scope="row" className="px-6 py-4 font-medium text-white-900 ">
                                                                            <div className="flex">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white-800  my-1">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                                                                            </svg>
                                                                            <p className="my-2 mx-4">{currentInput.response.outputSpeech}</p>
                                                                            </div>
                                                                            
                                                                        </td>
                                                                        <td colspan="100%" scope="row" className="px-6 py-4 font-medium text-white-900 ">
                                                                            {currentInput.response.reprompt}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            ) : ''}

                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })) : ''
                    }
                </>
            )
        })}
    </>)
}

export default Transcript;