import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit';

export const Navbar = (props) => {

    const navigate = useNavigate();
    var { state, pathname } = useLocation();
    const signOut = useSignOut();

    console.log('Navbar props', props);

    //Listeners
    const BtnClickLogout = async () => {
        signOut();
        navigate("/login")

    }

    return (<>
        <nav className="bg-[#574141]">
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">

                        <button type="button" className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                            <span className="absolute -inset-0.5"></span>
                            <span className="sr-only">Open main menu</span>

                            <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>

                            <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex flex-shrink-0 items-center">
                            <img className="h-8 w-auto" src="http://modal.systems/wp-content/uploads/2021/04/logo2.png" alt="Your Company" />
                        </div>
                        <div className="hidden sm:ml-6 sm:block">
                            <div className="flex space-x-4">

                                <Link to="/" className={`text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium ${pathname === "/" ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' : ''}`} aria-current="page">Dashboard</Link>
                                <Link to="/channels" className={`text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium ${pathname === "/channels" ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' : ''}`} aria-current="page">Channels</Link>
                                {/*(props.userDetails.role =='role_admin'?<Link to="/admin" className={`text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium ${pathname === "/admin"?'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium':''}`} aria-current="page">Administration</Link>:'')*/}
                                {((props.userDetails.role == 'role_admin' || props.userDetails.role == 'role_super') ? <Link to="/allusers" className={`text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium ${pathname === "/allusers" ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' : ''}`} aria-current="page">All Users</Link> : '')}
                                {((props.userDetails.role == 'role_super') ? <Link to="/createuser" className={`text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium ${pathname === "/createuser" ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' : ''}`} aria-current="page">Create User</Link> : '')}
                                <Link to="/settings" className={`text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium ${pathname === "/settings" ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' : ''}`} aria-current="page">Settings</Link>
                            </div>
                        </div>
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        {/*<button type="button" className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5"></span>
                            <span className="sr-only">View notifications</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                            </svg>
                        </button>*/}
                        <p className='text-white'>{props.userDetails.email}</p>
                        <div className="relative ml-3 border border-gray-700">
                            <div>
                                <img className="h-8 w-8 rounded-full" src={props.userDetails.profilePicture} />

                            </div>
                        </div>
                        <div className="relative ml-3">
                            <button onClick={BtnClickLogout} type="button" className="text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[#bb4d2a] hover:bg-gray-700 inline-flex items-center">Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

    </>)
}